import _ from 'lodash';
import moment, { MomentInput } from 'moment';

import I18n from 'common/i18n';
import { Vif, InlineDataRows, VisualizationAggregationFunction } from 'common/visualizations/vif';
import { VIF_CONSTANTS } from 'common/authoring_workflow/constants';
import { ViewColumn } from 'common/types/viewColumn';
import { WithComputedMeasureInjectedProps } from 'common/performance_measures/components/withComputedMeasure';

import { Lens, Measure, ComputedMeasureSeries, MetricConfig, ComputedMeasure } from '../../types';
import { ComputedMeasureChartProps } from './ComputedMeasureChart';
import { AxisScalingTypes, CalculationTypes, PeriodTypes, TIMESTAMP_WITHOUT_ZONE } from '../../lib/constants';
import { getPercentScaleMultiplier } from '../../lib/percents';
import { getColumnFormat, hasMeasureEnded } from '../../lib/measureHelpers';
import { shouldShowTargets, modifyVifForTargets } from './targetHelpers';
import { addViewColumnsToVif, modifyVifForSummaryTable } from './summaryTable';

/**
 * Converts a date instance to a floating timestamp string
 * that can be interpreted by the charting library.
 * @param date - momentjs instance | Date instance | ISO string
 */
const dateToVizTimestamp = (date: MomentInput): string => moment(date).format(TIMESTAMP_WITHOUT_ZONE);

/**
 * Returns true if the last row in the series has finite data.
 * @param series
 */
const hasDataInCurrentReportingPeriod = (series: ComputedMeasureSeries) => {
  if (_.isEmpty(series)) {
    return false;
  }

  const lastDataPoint = _.last(series);

  return lastDataPoint && lastDataPoint[1] && lastDataPoint[1].isFinite();
};

/**
 * Measure calculation magnitude data is provided as BigNumber instances, which are not suitable
 * for display to the user. This formats the BigNumber instances into string values which can
 * be displayed to the user via the charting library. Presently, the transformation is:
 * 1. If the data point is not finite (null, NaN), return null and stop processing the row.
 * 2. If the data point is a percentage, scale it to 0-100.
 * 3. Call BigNumber#toNumber on the data point, accepting whatever its defaults are, and return.
 */
const formatRowsForChart = (
  calculationColumns: ViewColumn[],
  calculationType: CalculationTypes,
  series: ComputedMeasureSeries
): InlineDataRows => {
  const multiplier = getPercentScaleMultiplier(calculationColumns, calculationType);
  return _.map(series, ([date, value]) => [
    date,
    value && value.isFinite() ? value.times(multiplier).toNumber() : null
  ]);
};

const hasRequiredFields = (measure: Measure): boolean => {
  const { metricConfig } = measure;
  const reportingPeriodConfig = metricConfig?.reportingPeriod;
  const reportingPeriodType = reportingPeriodConfig?.type;
  /* eslint @typescript-eslint/no-non-null-asserted-optional-chain: "warn" */
  const reportingPeriodSize = reportingPeriodConfig?.size!;
  const dateColumn = metricConfig?.dateColumn;
  const dataSourceLensUid = measure?.dataSourceLensUid;

  // All these are required to to draw a visualization.
  const requiredFields = [reportingPeriodType, reportingPeriodSize, dateColumn, dataSourceLensUid];

  return _.every(requiredFields);
};

export const calculationTypeToVifAggregation = (
  calculationType: CalculationTypes
): VisualizationAggregationFunction => {
  switch (calculationType) {
    case CalculationTypes.AVERAGE:
      return 'avg';
    case CalculationTypes.COUNT:
      return 'count';
    case CalculationTypes.SUM:
      return 'sum';
    default:
      return null;
  }
};

const shapeVif = (
  series: ComputedMeasureSeries,
  metricConfig: MetricConfig,
  originUrl: string | null,
  originTitle: string,
  formattedRows: InlineDataRows,
  measure: Measure,
  computedMeasure: ComputedMeasure,
  lens: Lens | undefined,
  showChartTitle: boolean
): Vif => {
  const i18nScope = 'shared.performance_measures';

  const lastRow = _.last(series);
  const unitLabel = metricConfig?.display?.label;
  const pluralUnitLabel = metricConfig?.display?.pluralLabel;
  const yAxisScaling = metricConfig?.display?.yAxis?.scaling;
  const yAxisCustomMax = metricConfig?.display?.yAxis?.customMax;
  const yAxisCustomMin = metricConfig?.display?.yAxis?.customMin;
  /* eslint @typescript-eslint/no-non-null-asserted-optional-chain: "warn" */
  const dateColumn = metricConfig?.dateColumn!;
  /* eslint @typescript-eslint/no-non-null-asserted-optional-chain: "warn" */
  const calculationType = metricConfig?.type!;
  const dataSourceLensUid = measure?.dataSourceLensUid;
  const { calculationColumns } = computedMeasure;
  const measureFieldName = `${calculationType}_for_${dataSourceLensUid}`;
  const measureName = I18n.t('chart.value', { scope: i18nScope });
  const measureEnded = hasMeasureEnded(measure);

  const columnFormat = getColumnFormat(measure, calculationColumns!, { fieldName: measureFieldName });
  const isCustomScaling = yAxisScaling === AxisScalingTypes.CUSTOM;
  const reportingPeriodConfig = metricConfig?.reportingPeriod;
  const reportingPeriodType = reportingPeriodConfig?.type;
  /* eslint @typescript-eslint/no-non-null-asserted-optional-chain: "warn" */
  const reportingPeriodSize = reportingPeriodConfig?.size!;
  const pointStyle =
    reportingPeriodType === PeriodTypes.OPEN && !measureEnded && hasDataInCurrentReportingPeriod(series!)
      ? 'last-open'
      : 'closed';

  return {
    configuration: {
      hideNullsInFlyout: true,
      viewSourceDataLink: true,
      connectNonAdjacentPoints: true,
      ...(lastRow && { dimensionAxisMaxValue: dateToVizTimestamp(moment(lastRow[0])) }),
      ...(isCustomScaling && yAxisCustomMin && { measureAxisMinValue: _.toNumber(yAxisCustomMin) }),
      ...(isCustomScaling && yAxisCustomMax && { measureAxisMaxValue: _.toNumber(yAxisCustomMax) }),
      ...(yAxisScaling === AxisScalingTypes.MIN_MAX && { measureAxisScale: 'min_to_max' })
    },
    origin: {
      url: originUrl,
      title: originTitle
    },
    series: [
      {
        lineStyle: {
          points: pointStyle,
          pointRadius: 4
        },
        dataSource: {
          precision: reportingPeriodSize.toUpperCase(),
          dimension: {
            columnName: dateColumn,
            aggregationFunction: null
          },
          measure: {
            // "Measure" the charting concept, not to be confused with POC Measures, the Socrata product.
            // KPIs should set each measure columnName to something unique.
            columnName: measureFieldName,
            columnFormat,
            // Used in the summary table column headers
            aggregationFunction: calculationTypeToVifAggregation(calculationType)
          },
          type: 'socrata.inline',
          rows: formattedRows
        },
        label: measureName,
        type: 'timelineChart',
        unit: {
          one: unitLabel || pluralUnitLabel,
          other: pluralUnitLabel || unitLabel
        }
      }
    ],
    format: {
      type: 'visualization_interchange_format',
      version: VIF_CONSTANTS.LATEST_VERSION
    },
    ...(!!showChartTitle && { title: measure.metadata?.shortName || lens?.name })
  };
};

const getOriginAttributes = (
  dataSourceName: string,
  dataSourceDomain: string | null,
  dataSourceLensUid?: string
): { originTitle: string; originUrl: string | null } => {
  let originUrl: string | null = `/d/${dataSourceLensUid}`;
  let originTitle = dataSourceName;

  if (dataSourceDomain) {
    originUrl = `https://${dataSourceDomain}${originUrl}`;
    originTitle = dataSourceName;
  }

  return { originTitle, originUrl };
};

const modifyVifForMeasureEnded = (vif: Vif): Vif => {
  _.set(vif, 'series[0].color.primary', '#c8c8c8'); // $light-grey-3

  return vif;
};

export const generateVifFromMeasure = ({
  computedMeasure,
  dataSourceDomain,
  dataSourceName,
  measure,
  lens,
  showChartTitle,
  timelineScope
}: ComputedMeasureChartProps & WithComputedMeasureInjectedProps): Vif | null => {
  if (!measure || !hasRequiredFields(measure)) {
    return null;
  }

  const { metricConfig } = measure;
  const { calculationColumns, dateColumn, series } = computedMeasure;

  /* eslint @typescript-eslint/no-non-null-asserted-optional-chain: "warn" */
  const calculationType = metricConfig?.type!;
  const dataSourceLensUid = measure?.dataSourceLensUid;
  const measureEnded = hasMeasureEnded(measure);

  const columnName = `${calculationType}_for_${dataSourceLensUid}`;
  const columnFormat = getColumnFormat(measure, calculationColumns!, { fieldName: columnName });
  // The metadata from the view sometimes/often has the 'name' key set to the name of the field,
  // and this chunk of the metadata is used for calculationColumns above, which we extend to make
  // columnFormat. When we add targets later, the flyout uses that field first as the label for
  // the key in the flyout, instead of the target label we add in modifyVifForTargets.
  // See EN-32497 for a complete trace
  delete columnFormat.name;

  const formattedRows = formatRowsForChart(calculationColumns!, calculationType, series!);
  const { originUrl, originTitle } = getOriginAttributes(dataSourceName, dataSourceDomain, dataSourceLensUid);

  let vif = shapeVif(
    series!,
    metricConfig,
    originUrl,
    originTitle,
    formattedRows,
    measure,
    computedMeasure,
    lens,
    showChartTitle
  );
  vif = measureEnded ? modifyVifForMeasureEnded(vif) : vif;

  vif = modifyVifForSummaryTable(vif, measure);
  vif = shouldShowTargets(measure, timelineScope)
    ? modifyVifForTargets(vif, measure, formattedRows, columnFormat)
    : vif;
  // There's an early exit if the date column does not exist
  vif = addViewColumnsToVif(vif, columnFormat, dateColumn!);

  return vif;
};

import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RELATIVE_FILTERS } from 'common/dates';
import I18n from 'common/i18n';

import SingleSourceFilterBar from 'common/components/SingleSourceFilterBar';
import FilterBar from 'common/components/FilterBar';
import { setFilters } from '../../actions/editor';
import { FilterItemType } from 'common/types/reportFilters';

export const mapStateToProps = (state, ownProps) => {
  const { filters } = ownProps;
  const columns = _.get(state, 'editor.displayableFilterableColumns');
  const datasetUid = _.get(state, 'editor.measure.dataSourceLensUid');
  const domain = window.location.host;
  const scope = 'shared.components.filter_bar.calendar_date_filter';
  const relativeDateFilters = [
    {
      label: I18n.t('relative_periods.date_to_today', { scope }),
      value: RELATIVE_FILTERS.DATE_TO_TODAY
    }
  ];

  let filterParamConfigs;
  if (filters) {
    filterParamConfigs = filters.map((filter) => {
      return {
        type: FilterItemType.FILTER,
        config: filter
      };
    });
  }


  return {
    columns: { [datasetUid]: columns },
    dataSource: [{ datasetUid: datasetUid, domain: domain }],
    isReadOnly: false,
    filterParameterConfigurations: filterParamConfigs,
    relativeDateOptions: relativeDateFilters,
    shouldRenderAddFilter: true,
    shouldMaintainColumnName: false,
    showRemoveButtonInFilterEditor: true
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { filterTarget } = ownProps;

  return {
    onUpdate(filters) {
      dispatch(setFilters(filters, filterTarget));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(FilterBar);

import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumericInput from 'react-numeric-input';

import I18n from 'common/i18n';
import BlockLabel from 'common/components/BlockLabel';
import Button from 'common/components/Button';
import Checkbox from 'common/components/Checkbox';
import Dropdown from 'common/components/Dropdown';
import Radiobutton from 'common/components/Radiobutton';
import {
  AxisScalingTypes,
  DefaultTimelineSamplingSizes,
  PeriodSizes,
  StartDateTypes,
  TargetTypes,
  TimelineScopes
} from 'common/performance_measures/lib/constants';
import * as ReportingPeriods from 'common/performance_measures/lib/reportingPeriods';
import measurePropType from 'common/performance_measures/propTypes/measurePropType';

import hasUserEnteredTargets from 'common/measures_editor/lib/hasUserEnteredTargets';
import { EditTabs } from 'common/measures_editor/lib/constants';
import {
  removeEndDate,
  setActivePanel,
  setEndDate,
  setEndDateStatusLabelOverride,
  setStartDate,
  setStartDateDuration,
  setTimelineSampling,
  setTimelineScope,
  setYAxisCustomBounds,
  setYAxisScaling,
  toggleEndDateStatusOverride
} from 'common/measures_editor/actions/editor';
import {
  isEndDateValid,
  shouldShowTimelineScopeConfigOptions
} from 'common/performance_measures/lib/measureHelpers';
import XAxisEndsBeforeDateSelection from './ChartOptionsPanel/XAxisEndsBeforeDateSelection';
import XAxisStartSelection from './ChartOptionsPanel/XAxisStartSelection';
import CalculatedBounds from './ChartOptionsPanel/CalculatedBounds';

// Configuration panel for timeline display options.
export class ChartOptionsPanel extends Component {

  translationScope = 'shared.measures_editor.measure.edit_modal.chart_options'

  renderTimelineXAxisStart() {
    const {
      firstQuarterStartMonth,
      onChangeStartDate,
      onChangeStartDateDuration,
      reportingPeriodSize,
      startDateObject,
      measure,
      targets
    } = this.props;

    const willTargetsBeReset =
      _.get(_.head(targets), 'type') === TargetTypes.PERIODIC &&
      hasUserEnteredTargets(targets);

    const onChangeReportingPeriod = (date) => {
      if (!willTargetsBeReset ||
        window.confirm(I18n.t('confirm_override_targets', { scope: this.translationScope }))
      ) {
        onChangeStartDate(date);
        return true;
      }
      return false;
    };

    const xAxisStartSelectionProps = {
      firstQuarterStartMonth,
      key: startDateObject,
      onChangeReportingPeriod,
      onChangeDuration: onChangeStartDateDuration,
      reportingPeriodSize,
      selectedDate: startDateObject.date,
      selectedDuration: startDateObject.duration,

      validationWarningText: !isEndDateValid(measure) ?
        I18n.t('timeline_xaxis_start.warning', { scope: this.translationScope }) :
        null
    };

    return (
      <div id="x-axis-start-controls" className="configuration-field">
        <h6>{I18n.t('timeline_xaxis_start.label', { scope: this.translationScope })}</h6>
        {I18n.t('timeline_xaxis_start.description', { scope: this.translationScope })}
        <XAxisStartSelection {...xAxisStartSelectionProps} />
      </div>
    );
  }

  renderXAxisEndStatusOverride() {
    const {
      endsBeforeDate,
      hasStatusOverride,
      onChangeEndDateStatusLabelOverride,
      onToggleEndDateStatusOverride,
      statusOverrideLabel
    } = this.props;

    const scope = `${this.translationScope}.timeline_xaxis_end`;

    const checkboxOptions = {
      id: 'x-axis-end-status-override-appearance',
      className: 'x-axis-end-status-override-appearance',
      checked: hasStatusOverride,
      disabled: _.isEmpty(endsBeforeDate),
      onChange: onToggleEndDateStatusOverride
    };

    const textInputOptions = {
      id: 'x-axis-end-status-override-text',
      className: 'text-input',
      disabled: _.isEmpty(endsBeforeDate) || !hasStatusOverride,
      maxLength: 20,
      type: 'text',
      onChange: (e) => onChangeEndDateStatusLabelOverride(e.target.value),
      placeholder: I18n.t('status_override_label_placeholder', { scope }),
      value: statusOverrideLabel
    };

    return (
      <div className="x-axis-end-status-override">
        <Checkbox {...checkboxOptions}>
          <span className="status-override-description">
            {I18n.t('status_override', { scope })}
          </span>
        </Checkbox>
        <input {...textInputOptions} />
      </div>
    );
  }

  renderTimelineXAxisEnd() {
    const {
      endsBeforeDate,
      firstQuarterStartMonth,
      measure,
      onChangeEndDate,
      onRemoveEndDate,
      statusEnabled
    } = this.props;

    const size = _.get(measure, 'metricConfig.reportingPeriod.size', 'default');

    const xAxisEndsBeforeDateSelectionOptions = {
      firstQuarterStartMonth,
      key: endsBeforeDate,
      onChangeReportingPeriod: onChangeEndDate,
      reportingPeriod: _.get(measure, 'metricConfig.reportingPeriod', {}),
      endsBeforeDate,
      validationWarningText: !isEndDateValid(measure) ?
        I18n.t('timeline_xaxis_end.warning', { scope: this.translationScope }) :
        null
    };

    const blockLabelOptions = {
      description: I18n.t('timeline_xaxis_end.tooltip_description', { scope: this.translationScope }),
      title: I18n.t('timeline_xaxis_end.label', { scope: this.translationScope }),
      titleClassName: 'h6'
    };

    const removeButton = (
      <button className="btn btn-link" onClick={onRemoveEndDate}>
        {I18n.t('timeline_xaxis_end.remove', { scope: this.translationScope })}
      </button>
    );

    return (
      <div id="x-axis-end-controls" className="x-axis-end-controls configuration-field">
        <BlockLabel {...blockLabelOptions} />
        {I18n.t(`timeline_xaxis_end.description.${size}`, { scope: this.translationScope })}
        <XAxisEndsBeforeDateSelection {...xAxisEndsBeforeDateSelectionOptions} />
        {!_.isEmpty(endsBeforeDate) && removeButton}
        {statusEnabled && this.renderXAxisEndStatusOverride()}
      </div>
    );
  }

  renderXAxisSection() {
    return (<section className="measure-edit-modal-section">
      <h5>{I18n.t('timeline_xaxis_title', { scope: this.translationScope })}</h5>
      {this.renderTimelineXAxisStart()}
      {this.renderTimelineXAxisEnd()}
    </section>);
  }

  renderYAxisSection() {
    const scope = `${this.translationScope}.y_axis`;
    const { measure, onSetYAxisScaling, onSetYAxisCustomBounds } = this.props;
    const yAxisScaling = _.get(measure, 'metricConfig.display.yAxis.scaling', AxisScalingTypes.MAX_ONLY);
    const yAxisCustomMax = _.get(measure, 'metricConfig.display.yAxis.customMax', '');
    const yAxisCustomMin = _.get(measure, 'metricConfig.display.yAxis.customMin', '');

    const scalingOptions = _.values(AxisScalingTypes).map((type) => {
      const customBounds = (type === AxisScalingTypes.CUSTOM) ? (<div>
        <label className="custom-bounds-label">
          <div className="custom-bounds-label-text">{I18n.t('customMin', { scope })}</div>
          <NumericInput
            id="y-axis-custom-min"
            className="custom-bounds-input"
            disabled={yAxisScaling !== AxisScalingTypes.CUSTOM}
            value={yAxisCustomMin}
            onChange={(number, string) => onSetYAxisCustomBounds(yAxisCustomMax, string)} />
        </label>
        <label className="custom-bounds-label">
          <div className="custom-bounds-label-text">{I18n.t('customMax', { scope })}</div>
          <NumericInput
            id="y-axis-custom-max"
            className="custom-bounds-input"
            disabled={yAxisScaling !== AxisScalingTypes.CUSTOM}
            value={yAxisCustomMax}
            onChange={(number, string) => onSetYAxisCustomBounds(string, yAxisCustomMin)} />
        </label>
      </div>) : null;

      return (<Radiobutton
        id={`y-axis-scaling-${type}`}
        key={`y-axis-scaling-${type}`}
        className="y-axis-scaling"
        onChange={() => onSetYAxisScaling(type)}
        checked={type === yAxisScaling}
        label={I18n.t(`options.${type}`, { scope })}>
          {customBounds}
      </Radiobutton>);
    });

    return (<section className="measure-edit-modal-section">
      <h5>{I18n.t('title', { scope })}</h5>
      <h6>{I18n.t('description', { scope })}</h6>
      <CalculatedBounds measure={measure} />
      <div className="configuration-field">
        {scalingOptions}
      </div>
    </section>);
  }

  renderTimelineScope() {
    const { reportingPeriodValid, timelineScope, onSetTimelineScope, measure } = this.props;

    if (!shouldShowTimelineScopeConfigOptions(measure)) {
      // If we can't let the user choose CURRENT, no need to present them with the choice
      return null;
    }

    const radioButtons = _.map(TimelineScopes, (scope) => (
      <Radiobutton
        id={`timeline-scope-${scope}`}
        className="timeline-scope-radio"
        disabled={!reportingPeriodValid}
        key={`timeline-scope-${scope}`}
        checked={timelineScope === scope}
        onChange={() => onSetTimelineScope(scope)}
        label={I18n.t(`timeline_scope.${scope}`, { scope: this.translationScope })}>
      </Radiobutton>
    ));

    return (
      <div className="measure-edit-modal-section">
        <h5>{I18n.t('timeline_scope.label', { scope: this.translationScope })}</h5>
        {I18n.t('timeline_scope.description', { scope: this.translationScope })}
        <div className="configuration-field">
          {radioButtons}
        </div>
      </div>
    );
  }

  renderTimelineSampling() {
    const {
      reportingPeriodSize,
      reportingPeriodValid,
      onSetTimelineSampling,
      measure
    } = this.props;

    if (!shouldShowTimelineScopeConfigOptions(measure)) {
      // If CURRENT isn't an option, this option doesn't apply
      return null;
    }

    let { timelineSampling } = this.props;
    timelineSampling = timelineSampling || DefaultTimelineSamplingSizes[reportingPeriodSize];

    const validSamplingOptions = ReportingPeriods.getValidTimelineSamplingSizes(reportingPeriodSize);
    const dropdownProps = {
      id: 'displayed-reporting-periods',
      className: 'configuration-field',
      disabled: !reportingPeriodValid,
      options: _.map(validSamplingOptions, (samplingSize) => ({
        title: I18n.t(`timeline_sampling.${samplingSize}`, { scope: this.translationScope }),
        value: samplingSize
      })),
      value: timelineSampling,
      onSelection: (option) => {
        onSetTimelineSampling(option.value);
      },
      showOptionsBelowHandle: true
    };

    const reportingPeriodSizeScope = 'shared.measures_editor.measure.edit_modal.reporting_period.size';
    const periodText = I18n.t(
      reportingPeriodSize || 'not_configured', { scope: reportingPeriodSizeScope }
    ).toLowerCase();

    return (
      <div className="measure-edit-modal-section">
        <h5>{I18n.t('timeline_sampling.label', {
          period: periodText,
          scope: this.translationScope
        })}</h5>
        <Dropdown {...dropdownProps} />
      </div>
    );
  }

  renderConfigLinks() {
    const { openReportingPeriodTab } = this.props;

    const periodLink = (
      <Button
        inverse
        variant="primary"
        onClick={openReportingPeriodTab}>
        {I18n.t('set_reporting_period', { scope: 'shared.measures_editor.measure.edit_modal' })}
      </Button>
    );

    return (
      <div className="config-links">
        <div className="centerbox">
          <h2>
            {I18n.t('not_ready', { scope: this.translationScope })}
          </h2>
          {periodLink}
        </div>
      </div>
    );
  }

  render() {
    const { reportingPeriodValid } = this.props;
    const configLinks = reportingPeriodValid ? null : this.renderConfigLinks();

    return (
      <div>
        <h3>{I18n.t('title', { scope: this.translationScope })}</h3>
        {configLinks}
        <form onSubmit={(event) => event.preventDefault()}>
          {this.renderXAxisSection()}
          {this.renderYAxisSection()}
          {this.renderTimelineScope()}
          {this.renderTimelineSampling()}
        </form>
      </div>
    );
  }
}

ChartOptionsPanel.defaultProps = {
  timelineScope: TimelineScopes.ALL,
  startDateObject: {
    type: StartDateTypes.FIXED,
    date: moment().startOf('year').format('YYYY-MM-DD')
  }
};

ChartOptionsPanel.propTypes = {
  endsBeforeDate: PropTypes.string,
  firstQuarterStartMonth: PropTypes.number,
  hasStatusOverride: PropTypes.bool.isRequired,
  measure: measurePropType.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeStartDateDuration: PropTypes.func,
  onRemoveEndDate: PropTypes.func.isRequired,
  onSetTimelineSampling: PropTypes.func.isRequired,
  onSetTimelineScope: PropTypes.func.isRequired,
  onSetYAxisCustomBounds: PropTypes.func.isRequired,
  onSetYAxisScaling: PropTypes.func.isRequired,
  onChangeEndDateStatusLabelOverride: PropTypes.func.isRequired,
  onToggleEndDateStatusOverride: PropTypes.func.isRequired,
  openReportingPeriodTab: PropTypes.func.isRequired,
  reportingPeriodSize: PropTypes.string,
  reportingPeriodValid: PropTypes.bool,
  startDateObject: PropTypes.shape({
    type: PropTypes.string,
    duration: PropTypes.number,
    date: PropTypes.string
  }),
  statusEnabled: PropTypes.bool,
  statusOverrideLabel: PropTypes.string,
  targets: PropTypes.arrayOf(PropTypes.object),
  timelineSampling: PropTypes.string,
  timelineScope: PropTypes.string
};

function mapStateToProps(state) {
  const timelineOptions = _.pick(
    _.get(state, 'editor.measure.metricConfig.display'),
    ['timelineSampling', 'timelineScope']
  );

  const measure = _.get(state, 'editor.measure', {});
  const reportingPeriod = _.get(state, 'editor.measure.metricConfig.reportingPeriod', {});

  const reportingPeriodSize = reportingPeriod.size || PeriodSizes[0];
  const firstQuarterStartMonth = reportingPeriod.firstQuarterStartMonth;
  const reportingPeriodValid = ReportingPeriods.isFullyConfigured(reportingPeriod);
  const startDateObject = reportingPeriod.startDateConfig;
  const endsBeforeDate = reportingPeriod.endsBeforeDate;
  const targets = _.get(state, 'editor.measure.metricConfig.targets');

  const hasStatusOverride =
    _.get(state, 'editor.measure.metricConfig.status.hasMeasureEndStatusOverride', false);
  const statusOverrideLabel = _.get(state, 'editor.measure.metricConfig.status.labels.ended', '');

  const statusEnabled = !!_.get(state, 'editor.measure.metricConfig.status.type');
  const yAxis = _.get(measure, 'metricConfig.display.yAxis');

  return _.merge(
    timelineOptions,
    {
      endsBeforeDate,
      firstQuarterStartMonth,
      hasStatusOverride,
      measure,
      reportingPeriodSize,
      reportingPeriodValid,
      startDateObject,
      statusEnabled,
      statusOverrideLabel,
      targets,
      yAxis
    }
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onChangeEndDate: setEndDate,
    onChangeEndDateStatusLabelOverride: setEndDateStatusLabelOverride,
    onChangeStartDate: setStartDate,
    onChangeStartDateDuration: setStartDateDuration,
    onRemoveEndDate: removeEndDate,
    onSetTimelineSampling: setTimelineSampling,
    onSetTimelineScope: setTimelineScope,
    onToggleEndDateStatusOverride: toggleEndDateStatusOverride,
    openReportingPeriodTab: () => setActivePanel(EditTabs.REPORTING_PERIOD),
    onSetYAxisCustomBounds: setYAxisCustomBounds,
    onSetYAxisScaling: setYAxisScaling
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOptionsPanel);

import _ from 'lodash';

import { DataRow, InlineDataRows, Vif } from 'common/visualizations/vif';
import { ViewColumn } from 'common/types/viewColumn';
import { SoQLType } from 'common/types/soql';

import { Measure } from 'common/performance_measures/types';
import { getDisplayDateRange } from 'common/performance_measures/lib/reportingPeriods';


/**
 * Reformats the rows for the chart's summary table. Measure dimension
 * (the date) is converted to a human readable reporting period date range.
 */
export const formatChartRowsForSummaryTable = (
  measure: Measure,
  chartRows: InlineDataRows
): InlineDataRows => {
  return _.map(chartRows, (row) => formatChartRowForSummaryTable(measure, row));
};

export const formatChartRowForSummaryTable = (
  measure: Measure,
  [date, ...values]: DataRow
): DataRow => {
  const { metricConfig } = measure;
  const cumulativeMathStartDate = metricConfig?.arguments?.cumulativeMathStartDate;
  const isCumulativeMath = !!metricConfig?.arguments?.isCumulativeMath;
  const periodSize = metricConfig?.reportingPeriod?.size;

  return [
    getDisplayDateRange(date as string, cumulativeMathStartDate, isCumulativeMath, periodSize),
    ...values
  ];
};

/**
 * Having these set controls the formatting for the summary table data.
 * @see InlineDataProvider.getColumns()
 */
export const addViewColumnsToVif = (
  measureVif: Vif,
  numberColumnFormat: ViewColumn,
  dateColumn: ViewColumn
): Vif => {
  const vifViewColumns = _.chain(measureVif.series)
    .map((series) => {
      const measureFieldName = _.get(series, 'dataSource.measure.columnName');
      const measureName = _.get(series, 'label');

      // For Performance Measures, this is the calculation or target value
      if (!_.isEmpty(measureFieldName) && !_.isEmpty(measureName)) {
        return {
          ...numberColumnFormat,
          fieldName: measureFieldName,
          renderTypeName: SoQLType.SoQLNumberT,
          name: measureName
        };
      }
    })
    .value();

  // For Performance Measures, this is set for every series and always represents the date column
  const dimensionFieldName = _.get(measureVif, 'series[0].dataSource.dimension.columnName');

  if (!_.isEmpty(dimensionFieldName)) {
    // @ts-expect-error
    vifViewColumns.push({
      fieldName: dimensionFieldName,
      dataTypeName: SoQLType.SoQLFloatingTimestampT,
      renderTypeName: SoQLType.SoQLFloatingTimestampT
    } as any as ViewColumn);
  }

  // We do not use the dateColumn directly for the summary table dimension because it will always be a calendar_date,
  // and we render the reporting period range as a string for the summary table.
  const summaryTableDimensionFieldName = _.get(measureVif, 'series[0].dataSource.summaryTable.dimensionName');
  // @ts-expect-error
  vifViewColumns.push({
    fieldName: summaryTableDimensionFieldName,
    dataTypeName: SoQLType.SoQLTextT,
    renderTypeName: SoQLType.SoQLTextT,
    name: dateColumn?.name
  } as any as ViewColumn);

  return _.set(measureVif, 'series[0].dataSource.view.columns', vifViewColumns);
};

/**
 * Add performance measure specific summary table overrides to the VIF. This overrides both
 * the default timeline summary table rows and dimension column formatting.
 */
export const modifyVifForSummaryTable = (
  vif: Vif,
  measure: Measure
) => {
  const chartRows = _.get(vif, 'series[0].dataSource.rows', []);
  const summaryTableRows = formatChartRowsForSummaryTable(measure, chartRows);
  _.set(vif, 'series[0].dataSource.summaryTable.rows', summaryTableRows);

  // See addViewColumnsToVif for explanation
  _.set(vif, 'series[0].dataSource.summaryTable.dimensionName', 'summary_table_dimension');

  return vif;
};
